import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { isMobilePhone } from '../utils/helper';
import axios from 'axios';

export default function ContactUs() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const form = useRef();

  const titleAnimation = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  const bounceAnimation = {
    whileHover: { scale: 1.1, rotate: 5 },
    transition: { type: 'spring', stiffness: 300 },
  };

  const inputHoverEffect = {
    whileHover: { scale: 1.02, borderColor: '#ff6f61' },
  };

  const mapAnimation = {
    hidden: { opacity: 0, x: 200 },
    visible: { opacity: 1, x: 0 },
  };

  const sendContactForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    const formData = {
      name: form.current.name.value,
      email: form.current.email.value,
      subject: form.current.subject.value,
      message: form.current.message.value,
    };

    try {
      const response = await axios.post(`${BACKEND_URL}/contact`, formData);

      if (response.status === 200) {
        setSuccessMessage('Your message has been sent successfully!');
        setLoading(false);
        form.current.reset(); // Reset form fields
      }
    } catch (error) {
      console.error('Error sending contact form:', error);
      setErrorMessage(
        error.response?.data?.error ||
          'An error occurred while sending your message. Please try again.',
      );
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        paddingTop: isMobilePhone ? '20%' : '10%',
        paddingBottom: '5%',
      }}
    >
      <section className="section" id="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                {/* Title with a bounce effect */}
                <motion.h4
                  className="title mb-4"
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.8, ease: 'easeInOut' }}
                  variants={titleAnimation}
                >
                  Drop Us a Line!
                </motion.h4>
                {/* Description with fade-in effect */}
                <motion.p
                  className="text-muted mx-auto para-desc mb-0"
                  initial="hidden"
                  animate="visible"
                  variants={titleAnimation}
                  transition={{ duration: 1.2 }}
                >
                  We're excited to hear from you! Whether you have questions, want to book a party,
                  or just want to say hello, drop us a message, and we'll get back to you as soon as
                  we can!
                </motion.p>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Form with hover animations for inputs */}
            <div className="col-md-7 mt-4 pt-2">
              <div className="custom-form bg-white p-4 rounded shadow">
                {successMessage && (
                  <motion.div
                    className="alert alert-success"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {successMessage}
                  </motion.div>
                )}

                {errorMessage && (
                  <motion.div
                    className="alert alert-danger"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {errorMessage}
                  </motion.div>
                )}

                <form ref={form} onSubmit={sendContactForm}>
                  <div className="row">
                    <motion.div
                      className="col-lg-6 col-12"
                      whileHover="whileHover"
                      variants={inputHoverEffect}
                    >
                      <div className="mb-3">
                        <label className="form-label small fw-bold" htmlFor="name">
                          Your Name <span className="text-danger">*</span> :
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control border rounded"
                          placeholder="Your Name"
                          required
                        />
                      </div>
                    </motion.div>
                    <motion.div
                      className="col-lg-6 col-12"
                      whileHover="whileHover"
                      variants={inputHoverEffect}
                    >
                      <div className="mb-3">
                        <label className="form-label small fw-bold" htmlFor="email">
                          Your Email <span className="text-danger">*</span> :
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control border rounded"
                          placeholder="Your Email"
                          required
                        />
                      </div>
                    </motion.div>

                    <motion.div
                      className="col-12"
                      whileHover="whileHover"
                      variants={inputHoverEffect}
                    >
                      <div className="mb-3">
                        <label className="form-label small fw-bold" htmlFor="subject">
                          Subject :
                        </label>
                        <input
                          name="subject"
                          id="subject"
                          className="form-control border rounded"
                          placeholder="Subject"
                        />
                      </div>
                    </motion.div>
                    <motion.div
                      className="col-12"
                      whileHover="whileHover"
                      variants={inputHoverEffect}
                    >
                      <div className="mb-3">
                        <label className="form-label small fw-bold" htmlFor="message">
                          Your Message <span className="text-danger">*</span> :
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          rows="4"
                          className="form-control border rounded"
                          placeholder="Your Message"
                          required
                        ></textarea>
                      </div>
                    </motion.div>

                    {/* Button with hover bounce effect */}
                    <motion.div
                      className="col-sm-12 text-end"
                      whileHover="whileHover"
                      variants={bounceAnimation}
                    >
                      <button
                        type="submit"
                        id="submit"
                        name="send"
                        className="btn w-100 btn-custom"
                        disabled={loading}
                      >
                        {loading ? 'Sending...' : 'Send Message'}
                      </button>
                    </motion.div>
                  </div>
                </form>
              </div>
            </div>

            {/* Map with fade-in and scale effect */}
            <motion.div
              className="col-md-5 mt-4 pt-2"
              initial="hidden"
              whileInView="visible"
              transition={{ duration: 0.8, ease: 'easeInOut' }}
              variants={mapAnimation}
            >
              <div className="map" style={{ border: '5px dotted #FAA742', borderRadius: '2px' }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2959.5719422428406!2d-88.03584772338957!3d42.116637650444915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fa4ea30a19453%3A0x50b7be7257cbf069!2s295%20N%20Northwest%20Hwy%20b%2C%20Palatine%2C%20IL%2060067!5e0!3m2!1sen!2sus!4v1723932602586!5m2!1sen!2sus"
                  style={{ border: 0 }}
                  allowFullScreen
                  title="map"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
}
