import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const isMobilePhone = window.innerWidth <= 768;

export default function SideImage({
  imagePosition = 'right',
  imageUrl,
  title,
  description,
  smallImage,
  imageWidth,
  imageHeight,
}) {
  const isImageRight = imagePosition === 'right';

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const slideInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  const slideInRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  const slideFromDown = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };
  const ImageSection = () => (
    <motion.div
      className={`col-lg-6 ${
        isImageRight ? 'offset-lg-6 order-lg-2' : 'order-lg-1'
      } order-1 padding-less img jarallax`}
      style={{ backgroundImage: `url(${imageUrl})` }}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={isMobilePhone ? slideFromDown : isImageRight ? slideInRight : slideInLeft}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    ></motion.div>
  );

  const TextSection = () => (
    <motion.div
      className={`col-lg-6 ${isImageRight ? 'order-lg-1' : 'order-lg-2 offset-lg-6'} order-2`}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={isMobilePhone ? slideFromDown : isImageRight ? slideInRight : slideInLeft}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >
      <div className="cta-full-img-box">
        <div className="cta-image-content">
          <div style={{ flexDirection: 'row' }}>
            <h4 className="cta-image-head mb-4">
              <img src={smallImage} alt="boy" style={{ width: imageWidth, height: imageHeight }} />
              {title}
            </h4>
          </div>
          <div className="description">
            <p className="text-muted" dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
        </div>
      </div>
    </motion.div>
  );

  return (
    <section className="cta-full bg-light" ref={ref}>
      <div className="container-fluid">
        <div className="row position-relative">
          {isImageRight ? (
            <>
              <TextSection />
              <ImageSection />
            </>
          ) : (
            <>
              <ImageSection />
              <TextSection />
            </>
          )}
        </div>
      </div>
    </section>
  );
}
