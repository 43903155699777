import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { portfolioData } from '../data/data';
import { isMobile } from '../utils/helper';
import { motion } from 'framer-motion';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import ComingSoon from '../components/ComingSoon';
import Masonry from 'react-masonry-css';
import { AdvancedImage } from '@cloudinary/react';
import { cld, scale } from '../utils/cloudinary';

export default function Gallery() {
  const [selectCategory] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);

  useEffect(() => {
    // Generate Lightbox image URLs from publicIds
    const urls = portfolioData.map((item) => {
      const image = cld.image(item.publicId);
      image.resize(scale().width(1200));
      return image.toURL();
    });
    setLightboxImages(urls);
  }, []);

  const handleClick = (index) => {
    setActiveIndex(index);
    setIsOpen(true);
  };

  const filterData = selectCategory
    ? portfolioData.filter((item) => item.category === selectCategory)
    : portfolioData;

  // Define breakpoints for responsive masonry
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  // Animation variants
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1, // Delay between items
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  };

  const overlayVariants = {
    hover: {
      opacity: 1,
      scale: 1.05,
      transition: { duration: 0.3, ease: 'easeOut' },
    },
    initial: { opacity: 0 },
  };

  return (
    <>
      <section className={`section gallery-section ${isMobile() ? 'mobile' : 'desktop'}`} id="work">
        {/* Gallery Grid or Coming Soon */}
        <motion.div
          className="gallery-container"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {filterData.length > 0 ? (
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {filterData.map((item, index) => {
                // Create a CloudinaryImage instance
                const cldImage = cld.image(item.publicId);
                cldImage.resize(scale().width(isMobile() ? 400 : 600)); // Adjust size based on device

                return (
                  <motion.div
                    key={item.id}
                    className="picture-item"
                    variants={itemVariants}
                    whileHover="hover"
                  >
                    <div className="portfolio-box">
                      <Link
                        className="lightbox"
                        to="#"
                        onClick={() => handleClick(index)}
                        // title={item.title}
                      >
                        {/* Replace img with AdvancedImage */}
                        <AdvancedImage
                          cldImg={cldImage}
                          className="img-fluid gallery-image"
                          alt={item.title || 'Gallery Image'}
                          loading="lazy"
                        />
                        <motion.div
                          className="overlay-work"
                          variants={overlayVariants}
                          initial="initial"
                          whileHover="hover"
                        >
                          {/* <h3 className="overlay-title">{item.title}</h3> */}
                        </motion.div>
                      </Link>
                    </div>
                  </motion.div>
                );
              })}
            </Masonry>
          ) : (
            <ComingSoon /> // Render the ComingSoon component when no data
          )}
        </motion.div>
      </section>

      {/* Lightbox for larger view of images */}
      {isOpen && (
        <Lightbox
          mainSrc={lightboxImages[activeIndex]}
          nextSrc={lightboxImages[(activeIndex + 1) % lightboxImages.length]}
          prevSrc={
            lightboxImages[(activeIndex + lightboxImages.length - 1) % lightboxImages.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((activeIndex + lightboxImages.length - 1) % lightboxImages.length)
          }
          onMoveNextRequest={() => setActiveIndex((activeIndex + 1) % lightboxImages.length)}
          animationDuration={500}
          imageTitle={portfolioData[activeIndex]?.title}
          imageCaption={portfolioData[activeIndex]?.description}
        />
      )}
    </>
  );
}

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { portfolioData } from '../data/data';
// import { isMobile } from '../utils/helper';
// import { motion } from 'framer-motion';
// import Lightbox from 'react-18-image-lightbox';
// import 'react-18-image-lightbox/style.css';
// import ComingSoon from '../components/ComingSoon';
// import { AdvancedImage } from '@cloudinary/react';
// import { cld, scale } from '../utils/cloudinary';

// // Utility function to split array into chunks
// const chunkArray = (array, chunkSize) => {
//   const results = [];
//   for (let i = 0; i < array.length; i += chunkSize) {
//     results.push(array.slice(i, i + chunkSize));
//   }
//   return results;
// };

// export default function Gallery() {
//   const [selectCategory] = useState(null);
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [isOpen, setIsOpen] = useState(false);
//   const [lightboxImages, setLightboxImages] = useState([]);

//   useEffect(() => {
//     // Generate Lightbox image URLs from publicIds
//     const urls = portfolioData.map((item) => {
//       const image = cld.image(item.publicId);
//       image.resize(scale().width(1200));
//       return image.toURL();
//     });
//     setLightboxImages(urls);
//   }, []);

//   const handleClick = (index) => {
//     setActiveIndex(index);
//     setIsOpen(true);
//   };

//   const filterData = selectCategory
//     ? portfolioData.filter((item) => item.category === selectCategory)
//     : portfolioData;

//   // Group items into rows of 3
//   const rows = chunkArray(filterData, 3);

//   // Animation variants
//   const containerVariants = {
//     hidden: {},
//     visible: {
//       transition: {
//         staggerChildren: 0.3, // Delay between rows
//       },
//     },
//   };

//   const rowVariants = {
//     hidden: { opacity: 0, y: 30 },
//     visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
//   };

//   const imageVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
//   };

//   const overlayVariants = {
//     hover: {
//       opacity: 1,
//       scale: 1.05,
//       transition: { duration: 0.3, ease: 'easeOut' },
//     },
//     initial: { opacity: 0 },
//   };

//   return (
//     <>
//       <section
//         className="section gallery-section"
//         style={{
//           paddingTop: isMobile ? '20%' : '8%',
//         }}
//         id="work"
//       >
//         {/* Gallery Grid or Coming Soon */}
//         <motion.div
//           className="gallery-container"
//           variants={containerVariants}
//           initial="hidden"
//           animate="visible"
//         >
//           {filterData.length > 0 ? (
//             <motion.div className="gallery-grid">
//               {rows.map((row, rowIndex) => (
//                 <motion.div key={rowIndex} className="gallery-row" variants={rowVariants}>
//                   {row.map((item, index) => {
//                     // Create a CloudinaryImage instance
//                     const cldImage = cld.image(item.publicId);
//                     cldImage.resize(scale().width(600)); // Corrected transformation

//                     return (
//                       <motion.div key={item.id} className="picture-item" variants={imageVariants}>
//                         <div className="portfolio-box">
//                           <Link
//                             className="lightbox"
//                             to="#"
//                             onClick={() => handleClick(filterData.indexOf(item))}
//                             // title={item.title}
//                           >
//                             {/* Replace img with AdvancedImage */}
//                             <AdvancedImage
//                               cldImg={cldImage}
//                               className="img-fluid gallery-image"
//                               alt={item.title || 'Gallery Image'}
//                               loading="lazy"
//                             />
//                             <motion.div
//                               className="overlay-work"
//                               variants={overlayVariants}
//                               initial="initial"
//                               whileHover="hover"
//                             >
//                               {/* <h3 className="overlay-title">{item.title}</h3> */}
//                             </motion.div>
//                           </Link>
//                         </div>
//                       </motion.div>
//                     );
//                   })}
//                 </motion.div>
//               ))}
//             </motion.div>
//           ) : (
//             <ComingSoon />
//           )}
//         </motion.div>
//       </section>

//       {/* Lightbox for larger view of images */}
//       {isOpen && (
//         <Lightbox
//           mainSrc={lightboxImages[activeIndex]}
//           nextSrc={lightboxImages[(activeIndex + 1) % lightboxImages.length]}
//           prevSrc={
//             lightboxImages[(activeIndex + lightboxImages.length - 1) % lightboxImages.length]
//           }
//           onCloseRequest={() => setIsOpen(false)}
//           onMovePrevRequest={() =>
//             setActiveIndex((activeIndex + lightboxImages.length - 1) % lightboxImages.length)
//           }
//           onMoveNextRequest={() => setActiveIndex((activeIndex + 1) % lightboxImages.length)}
//           animationDuration={500}
//           imageTitle={portfolioData[activeIndex]?.title}
//           imageCaption={portfolioData[activeIndex]?.description}
//         />
//       )}
//     </>
//   );
// }

// // import React, { useState, useEffect } from 'react';
// // import { Link } from 'react-router-dom';
// // import { portfolioData } from '../data/data';
// // import { isMobile } from '../utils/helper';
// // import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
// // import { motion } from 'framer-motion';
// // import Lightbox from 'react-18-image-lightbox';
// // import 'react-18-image-lightbox/style.css';
// // import ComingSoon from '../components/ComingSoon';
// // import { AdvancedImage } from '@cloudinary/react';
// // import { cld, scale } from '../utils/cloudinary';

// // export default function Gallery() {
// //   const [selectCategory] = useState(null);
// //   const [activeIndex, setActiveIndex] = useState(0);
// //   const [isOpen, setIsOpen] = useState(false);
// //   const [lightboxImages, setLightboxImages] = useState([]);

// //   useEffect(() => {
// //     // Generate Lightbox image URLs from publicIds
// //     const urls = portfolioData.map((item) => {
// //       const image = cld.image(item.publicId);
// //       image.resize(scale().width(1200));
// //       return image.toURL();
// //     });
// //     setLightboxImages(urls);
// //   }, []);

// //   const handleClick = (index) => {
// //     setActiveIndex(index);
// //     setIsOpen(true);
// //   };

// //   const filterData = selectCategory
// //     ? portfolioData.filter((item) => item.category === selectCategory)
// //     : portfolioData;

// //   // Animation variants
// //   const containerVariants = {
// //     hidden: { opacity: 0 },
// //     visible: {
// //       opacity: 1,
// //       transition: {
// //         staggerChildren: 0.1,
// //       },
// //     },
// //   };

// //   const imageVariants = {
// //     hidden: { opacity: 0, y: 30 },
// //     visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
// //   };

// //   const overlayVariants = {
// //     hover: {
// //       opacity: 1,
// //       scale: 1.05,
// //       transition: { duration: 0.3, ease: 'easeOut' },
// //     },
// //     initial: { opacity: 0 },
// //   };

// //   return (
// //     <>
// //       <section
// //         className="section"
// //         id="work"
// //         style={{
// //           paddingTop: isMobile ? '20%' : '10%',
// //           position: 'relative',
// //           overflow: 'hidden',
// //         }}
// //       >
// //         {/* Gallery Grid or Coming Soon */}
// //         <motion.div
// //           className="container"
// //           variants={containerVariants}
// //           initial="hidden"
// //           animate="visible"
// //           style={{
// //             paddingTop: isMobile ? '20%' : '%',
// //           }}
// //         >
// //           <div id="grid" className="row pt-2 mt-4">
// //             {filterData.length > 0 ? (
// //               <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
// //                 <Masonry>
// //                   {filterData.map((item, index) => {
// //                     // Create a CloudinaryImage instance
// //                     const cldImage = cld.image(item.publicId);
// //                     cldImage.resize(scale().width(600)); // Corrected transformation

// //                     return (
// //                       <motion.div
// //                         key={item.id}
// //                         className="picture-item my-2 mx-2 "
// //                         variants={imageVariants}
// //                       >
// //                         <div className="portfolio-box position-relative">
// //                           <Link
// //                             className="lightbox"
// //                             to="#"
// //                             onClick={() => handleClick(filterData.indexOf(item))}
// //                             title={item.title}
// //                           >
// //                             {/* Replace img with AdvancedImage */}
// //                             <AdvancedImage
// //                               cldImg={cldImage}
// //                               className="img-fluid"
// //                               alt={item.title || 'Gallery Image'}
// //                               loading="lazy"
// //                             />
// //                             <motion.div
// //                               className="overlay-work d-flex flex-column justify-content-center align-items-center text-center text-white"
// //                               variants={overlayVariants}
// //                             ></motion.div>
// //                           </Link>
// //                         </div>
// //                       </motion.div>
// //                     );
// //                   })}
// //                 </Masonry>
// //               </ResponsiveMasonry>
// //             ) : (
// //               <ComingSoon /> // Render the ComingSoon component when no data
// //             )}
// //           </div>
// //         </motion.div>
// //       </section>

// //       {/* Lightbox for larger view of images */}
// //       {isOpen && (
// //         <Lightbox
// //           mainSrc={lightboxImages[activeIndex]}
// //           nextSrc={lightboxImages[(activeIndex + 1) % lightboxImages.length]}
// //           prevSrc={
// //             lightboxImages[(activeIndex + lightboxImages.length - 1) % lightboxImages.length]
// //           }
// //           onCloseRequest={() => setIsOpen(false)}
// //           onMovePrevRequest={() =>
// //             setActiveIndex((activeIndex + lightboxImages.length - 1) % lightboxImages.length)
// //           }
// //           onMoveNextRequest={() => setActiveIndex((activeIndex + 1) % lightboxImages.length)}
// //           animationDuration={500}
// //           imageTitle={portfolioData[activeIndex]?.title}
// //           imageCaption={portfolioData[activeIndex]?.description}
// //         />
// //       )}
// //     </>
// //   );
// // }
