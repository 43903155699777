import React from 'react';
import { motion } from 'framer-motion';
import { isMobilePhone } from '../utils/helper';
// import { handwriting } from "../assets/css/handwriting.css";

export default function About() {
  const titleAnimation = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  const paragraphAnimation = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };
  return (
    <div style={{ marginTop: isMobilePhone ? '20%' : '5%' }}>
      <section className="section pb-0" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-5">
                <motion.h4
                  className="title text-uppercase fw-bold mb-5"
                  initial="hidden"
                  animate="visible"
                  variants={titleAnimation}
                  transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                  Our Story
                </motion.h4>
                <motion.p
                  className="text-muted mx-auto para-desc mb-0"
                  initial="hidden"
                  animate="visible"
                  variants={paragraphAnimation}
                  transition={{ delay: 0.2, duration: 1, ease: 'easeInOut' }}
                >
                  Welcome to Funlandia Cafe, where family fun comes to life! Our family-owned oasis
                  is designed for kids and moms to create lasting memories together. With a vibrant
                  array of toys and attractions, children can explore, laugh, and play to their
                  hearts' content, while parents unwind in our cozy, welcoming space. Whether you're
                  celebrating a birthday, hosting a group party, or simply enjoying a day out,
                  Funlandia Cafe is your destination to go. "Come join us, and let the fun begin!
                  Our space is designed for joy, relaxation, and unforgettable family moments, where
                  both kids and parents can enjoy themselves together."
                </motion.p>
                <br />
                <motion.p
                  className="text-muted mx-auto para-desc mb-0"
                  initial="hidden"
                  animate="visible"
                  variants={paragraphAnimation}
                  transition={{ delay: 0.2, duration: 1, ease: 'easeInOut' }}
                >
                  Funlandia Kid Café is a small, family owned business. We are parents too, and that
                  is what inspired us to create this space. Funlandia Kid Café has created a
                  kid-friendly indoor environment that is stimulating and enjoyable for your kids.
                  Our mission is to create a joyful haven where children explore, and play, while
                  parents enjoy coffee and treats. We also provide spaces for all your celebrations
                  (birthday parties, graduations, get together parties, sports and cheerleading
                  parties, family gatherings). Our staff are always here to help you organize the
                  best memories for your child and their friends. Experience a personalized birthday
                  party dedicated to your child that they will never forget!
                </motion.p>
                <div className="handwriting-container">
                  {/* <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 800 200"
                    className="handwriting"
                  > */}
                  <text x="50%" y="50%" textAnchor="middle" className="handwriting-text">
                    Every day is a special day
                  </text>
                  {/* </svg> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-8 mt-4 pt-2 text-center">
              <p className="about-funlandia"></p>
              {/* <VideoTwo /> */}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">{/* <img src={about} className="img-fluid" alt="" /> */}</div>
          </div>
        </div>
      </section>
    </div>
  );
}
