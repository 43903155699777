import React, { useState } from 'react';
import { motion } from 'framer-motion';
import menuBg from '../assets/images/background/food-menu.png';
import { menu } from '../data/data';
import { Link } from 'react-router-dom';
import { isMobilePhone } from '../utils/helper';

export default function Menu() {
  let [selectedCategory, setSelectedCategory] = useState(null);

  let filteredData = selectedCategory
    ? menu.filter((item) => item.category === selectedCategory)
    : menu;

  // Define animation for categories
  const categoryAnimation = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    transition: { duration: 0.5, ease: 'easeInOut' },
  };

  // Define animation for menu items
  const menuItemAnimation = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
    transition: { duration: 0.8, ease: 'easeOut' },
  };

  // Define hover effect for images
  const imageHoverEffect = {
    whileHover: { scale: 1.1 },
  };

  return (
    <div
      style={{
        paddingTop: isMobilePhone ? '20%' : '10%',
        paddingBottom: '5%',
      }}
    >
      <section
        className="section pt-5"
        style={{
          backgroundImage: `url(${menuBg})`,
          backgroundPosition: 'center',
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 filters-group-wrap text-center">
              <motion.div
                className="filters-group"
                initial="hidden"
                animate="visible"
                variants={categoryAnimation}
              >
                <ul className="col container-filter portfolioFilter list-unstyled mb-0 text-center filter-options">
                  <li
                    className={`list-inline-item mb-2 categories rounded ps-3 pe-3 font-weight-bold ${
                      selectedCategory === null ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCategory(null)}
                    whileTap={{ scale: 1.2 }}
                  >
                    All
                  </li>
                  <li
                    className={`list-inline-item mb-2 categories rounded ps-3 pe-3 font-weight-bold ${
                      selectedCategory === 'hotfood' ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCategory('hotfood')}
                    whileTap={{ scale: 1.2 }}
                  >
                    Hot Food
                  </li>
                  <li
                    className={`list-inline-item mb-2 categories rounded ps-3 pe-3 ${
                      selectedCategory === 'snacks' ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCategory('snacks')}
                    whileTap={{ scale: 1.2 }}
                  >
                    Snacks
                  </li>

                  <li
                    className={`list-inline-item mb-2 categories rounded ps-3 pe-3 ${
                      selectedCategory === 'sweets' ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCategory('sweets')}
                    whileTap={{ scale: 1.2 }}
                  >
                    Sweets
                  </li>
                  <li
                    className={`list-inline-item mb-2 categories rounded ps-3 pe-3 ${
                      selectedCategory === 'hotdrinks' ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCategory('hotdrinks')}
                    whileTap={{ scale: 1.2 }}
                  >
                    Hot Drinks
                  </li>
                  <li
                    className={`list-inline-item mb-2 categories rounded ps-3 pe-3 ${
                      selectedCategory === 'coldbeverages' ? 'active' : ''
                    }`}
                    onClick={() => setSelectedCategory('coldbeverages')}
                    whileTap={{ scale: 1.2 }}
                  >
                    Cold beverages
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
        </div>

        <div className="container mt-5" style={{ minHeight: '80vh' }}>
          <div id="grid" className="row ">
            {filteredData.map((item, index) => {
              return (
                <motion.div
                  className="col-md-6 col-sm-12 picture-item"
                  data-groups='["break"]'
                  key={index}
                  initial="hidden"
                  animate="visible"
                  variants={menuItemAnimation}
                  transition={{ delay: index * 0.1 }} // Staggered animation
                >
                  <div className="portfolio-box p-2 pt-1 pb-1">
                    <motion.div
                      className="thumb float-start me-4"
                      whileHover={imageHoverEffect.whileHover}
                    >
                      <img
                        src={item.image}
                        className="img-fluid shadow rounded-pill"
                        alt=""
                        style={{ width: 80 }}
                      />
                    </motion.div>
                    <div className="menu-content overflow-hidden">
                      <h4 className="mb-1 pb-2 title border-bottom text-capitalize">
                        <Link to="#" className="text-dark">
                          {item.name} <span className="float-end d-block">{item.price}</span>
                        </Link>
                      </h4>
                      <p className="text-muted">{item.desc}</p>
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
