import React, { useState, useRef, useEffect } from 'react';
import { isMobilePhone } from '../utils/helper';
import SignatureCanvas from 'react-signature-canvas';
import { generatePdf } from '../utils/pdfUtils';
import PdfGenerator from '../components/SignatureComponent';
import axios from 'axios';

export default function Waiver() {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    children: [{ name: '', dob: '' }],
    emailConfirmation: false,
    agreement: false,
  });

  const [signature, setSignature] = useState(null);
  const sigCanvas = useRef(null);
  const [showPdf, setShowPdf] = useState(false);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 400,
    height: 200,
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    children: [{ name: '', dob: '' }],
    signature: '',
    agreement: '',
  });

  // Regex patterns
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/;

  // Update canvas dimensions based on screen size
  const updateCanvasDimensions = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth < 576) {
      // Extra small devices (portrait phones)
      setCanvasDimensions({ width: 300, height: 150 });
    } else if (windowWidth >= 576 && windowWidth < 768) {
      // Small devices (landscape phones)
      setCanvasDimensions({ width: 350, height: 175 });
    } else {
      // Medium devices and up (tablets, desktops)
      setCanvasDimensions({ width: 500, height: 200 });
    }
  };

  useEffect(() => {
    // Set initial dimensions
    updateCanvasDimensions();

    // Add event listener for window resize
    window.addEventListener('resize', updateCanvasDimensions);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', updateCanvasDimensions);
  }, []);

  // Validation functions
  const validateField = (name, value) => {
    switch (name) {
      case 'firstName':
        if (!value.trim()) return '😕 Please enter your first name.';
        return '';
      case 'lastName':
        if (!value.trim()) return '😕 Please enter your last name.';
        return '';
      case 'email':
        if (!emailRegex.test(value)) return '📧 Please enter a valid email address.';
        return '';
      case 'phone':
        if (!phoneRegex.test(value)) return '📞 Please enter a valid phone number.';
        return '';
      case 'emailConfirmation':
        // No validation needed; it's a checkbox
        return '';
      case 'agreement':
        if (!value) return '✅ You must agree to the terms and conditions.';
        return '';
      default:
        return '';
    }
  };

  const validateChild = (child, index) => {
    const childErrors = { name: '', dob: '' };

    if (index === 0) {
      // Make the first child required
      if (!child.name.trim()) {
        childErrors.name = "😕 Please enter the child's name.";
      }
      if (!child.dob) {
        childErrors.dob = "📅 Please enter the child's date of birth.";
      }
    } else {
      // For additional children, only validate if at least one field is filled
      if (child.name.trim() || child.dob) {
        if (!child.name.trim()) {
          childErrors.name = "😕 Please enter the child's name.";
        }
        if (!child.dob) {
          childErrors.dob = "📅 Please enter the child's date of birth.";
        }
      }
    }

    return childErrors;
  };

  const isFormValid = () => {
    // Check for errors in static fields
    const noStaticErrors = Object.entries(errors).every(([key, value]) => {
      if (key !== 'children') {
        return value === '';
      } else {
        // For children, ensure both name and dob errors are empty
        return (
          Array.isArray(value) &&
          value.every((childError) => childError.name === '' && childError.dob === '')
        );
      }
    });

    // Check if all required static fields are filled
    const staticFieldsFilled =
      formData.firstName.trim() &&
      formData.lastName.trim() &&
      emailRegex.test(formData.email) &&
      phoneRegex.test(formData.phone) &&
      signature &&
      formData.agreement;

    // Check if at least one child is filled and no errors in children
    const filledChildren = formData.children.filter(
      (child) => child.name.trim() !== '' && child.dob !== '',
    );
    const childrenValid =
      filledChildren.length > 0 &&
      filledChildren.every((child, index) => {
        const childIndex = formData.children.indexOf(child);
        return (
          child.name.trim() !== '' &&
          child.dob !== '' &&
          errors.children[childIndex].name === '' &&
          errors.children[childIndex].dob === ''
        );
      });

    return noStaticErrors && staticFieldsFilled && childrenValid;
  };

  const handleChange = (e, index = null) => {
    const { name, value, type, checked } = e.target;

    if (name.startsWith('children')) {
      const parts = name.split('_');
      // Expected parts: ['children', 'name', '{index}'] or ['children', 'dob', '{index}']
      if (parts.length < 3) return; // Invalid name format

      const field = parts[1]; // 'name' or 'dob'
      const childIndex = parseInt(parts[2], 10);

      if (isNaN(childIndex)) return; // Invalid index

      const updatedChildren = formData.children.map((child, i) => {
        if (i === childIndex) {
          return { ...child, [field]: value };
        }
        return child;
      });
      setFormData({ ...formData, children: updatedChildren });

      // Validate the specific child field
      const child = updatedChildren[childIndex];
      const childError = validateChild(child, childIndex);
      const updatedChildrenErrors = [...errors.children];
      updatedChildrenErrors[childIndex] = childError;
      setErrors({ ...errors, children: updatedChildrenErrors });
    } else {
      const fieldValue = type === 'checkbox' ? checked : value;
      setFormData({
        ...formData,
        [name]: fieldValue,
      });

      // Validate the specific field
      const fieldError = validateField(name, fieldValue);
      setErrors({ ...errors, [name]: fieldError });
    }
  };

  // Add a new child
  const addChild = () => {
    setFormData({
      ...formData,
      children: [...formData.children, { name: '', dob: '' }],
    });
    setErrors({
      ...errors,
      children: [...errors.children, { name: '', dob: '' }],
    });
  };

  // Remove a child
  const removeChild = (index) => {
    const updatedChildren = formData.children.filter((_, i) => i !== index);
    setFormData({ ...formData, children: updatedChildren });

    // Also remove the corresponding error
    const updatedChildrenErrors = errors.children.filter((_, i) => i !== index);
    setErrors({ ...errors, children: updatedChildrenErrors });
  };

  // Clear the signature canvas
  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignature(null);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;
    let tempErrors = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      children: formData.children.map(() => ({ name: '', dob: '' })),
      signature: '',
      agreement: '',
    };

    // Validate First Name
    if (!formData.firstName.trim()) {
      tempErrors.firstName = '😕 Please enter your first name.';
      valid = false;
    }

    // Validate Last Name
    if (!formData.lastName.trim()) {
      tempErrors.lastName = '😕 Please enter your last name.';
      valid = false;
    }

    // Validate Email
    if (!emailRegex.test(formData.email)) {
      tempErrors.email = '📧 Please enter a valid email address.';
      valid = false;
    }

    // Validate Phone Number
    if (!phoneRegex.test(formData.phone)) {
      tempErrors.phone = '📞 Please enter a valid phone number.';
      valid = false;
    }

    // Validate Children Information
    formData.children.forEach((child, index) => {
      const childError = validateChild(child, index);
      tempErrors.children[index] = childError;

      if (
        (index === 0 && (!child.name.trim() || !child.dob)) ||
        (index !== 0 && (child.name.trim() || child.dob) && (!child.name.trim() || !child.dob))
      ) {
        valid = false;
      }
    });

    // Ensure at least one child is fully filled
    const filledChildren = formData.children.filter(
      (child) => child.name.trim() !== '' && child.dob !== '',
    );

    if (filledChildren.length === 0) {
      tempErrors.children[0].name =
        tempErrors.children[0].name || "👶 Please enter at least one child's name.";
      tempErrors.children[0].dob =
        tempErrors.children[0].dob || "📅 Please enter at least one child's DOB.";
      valid = false;
    }

    // Validate Signature
    if (!signature) {
      tempErrors.signature = '✍️ Please provide your signature.';
      valid = false;
    }

    // Validate Agreement Checkbox
    if (!formData.agreement) {
      tempErrors.agreement = '✅ You must agree to the terms and conditions.';
      valid = false;
    }

    setErrors(tempErrors);

    if (!valid) {
      // Optionally, scroll to the first error
      const firstErrorField = document.querySelector('.is-invalid');
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    // Prepare data for PDF generation
    const dataToGenerate = {
      ...formData,
      children: filledChildren,
      signature: sigCanvas.current.toDataURL('image/png'),
    };

    try {
      // Generate PDF and get Base64 string
      const pdfBase64 = await generatePdf(dataToGenerate);

      const base64String = pdfBase64.split(',')[1];

      const emailData = {
        toEmail: formData.email,
        fromName: `${formData.firstName} ${formData.lastName}`,
        attachment: base64String,
      };
      const response = await axios.post(`${BACKEND_URL}/send-waiver`, emailData);

      if (response.status === 200) {
        // Trigger PDF download
        const link = document.createElement('a');
        link.href = pdfBase64;
        link.download = `Funlandia-Waiver-${formData.firstName}-${formData.lastName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Show success message
        setShowPdf(true);
        setPdfData(dataToGenerate);
      } else {
        throw new Error('Failed to send email.');
      }
    } catch (error) {
      console.error('Error sending email:', error);

      // Extract error message
      const errorMessage =
        error.response?.data?.error?.message ||
        error.response?.data?.error?.errors?.[0]?.message ||
        error.message ||
        'There was an error submitting the form. Please try again later.';

      alert(`Error sending email: ${errorMessage}`);
    }
  };

  const [pdfData, setPdfData] = useState(null);

  return (
    <div
      style={{
        paddingTop: isMobilePhone ? '10%' : '5%',
        paddingBottom: '5%',
        userSelect: 'none',
      }}
    >
      <section className="section pb-0" id="about">
        <div className="container">
          <div className="mt-4 pt-2">
            <div className="p-4 shadow rounded">
              <div className="row justify-content-center">
                <div>
                  <h5
                    style={{
                      wordSpacing: '10px',
                      letterSpacing: '2px',
                      marginTop: '20px',
                      marginBottom: '20px',
                      textAlign: 'center',
                    }}
                  >
                    RELEASE OF LIABILITY
                  </h5>
                </div>
                {/* Address Section */}
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                  <strong>FUNLANDIA KID CAFÉ</strong>
                  <br />
                  295 N. NORTHWEST HWY UNIT B<br />
                  PALATINE, ILL 60067
                </div>

                {!showPdf ? (
                  <form onSubmit={handleSubmit} style={{ padding: '20px', color: '#000' }}>
                    {/* Waiver Text */}
                    <p>
                      I, as parent or legal guardian of the child (or children) whose name is set
                      forth below (each referred to as a "Participant" and collectively referred to
                      herein as the "Participants") and in consideration of the Participants being
                      permitted to participate in the Activities (as defined below) conducted by
                      Funlandia Kid Café located at 295 N. Northwest Hwy Unit B Palatine, IL 60067
                      (“Funlandia”) agree as follows:
                    </p>

                    <ol>
                      <li>
                        <strong>ACTIVITIES:</strong> Activities shall be defined as the various
                        activities and services offered to Participants by Funlandia Kid Café, an
                        indoor playground and cafe.
                      </li>
                      <br />
                      <li>
                        <strong>AGREEMENT TO FOLLOW DIRECTIONS:</strong> Participants agree to
                        observe and obey all posted rules and warnings, and further agree to follow
                        any verbal instructions and directions given by Funlandia Kid Café, or the
                        employees of Funlandia Kid Café. In lieu of absent verbal and written rules,
                        warnings and objections, Participants shall act with due regard to other
                        Participants, employees, and property.
                      </li>
                      <br />
                      <li>
                        <strong>ASSUMPTION OF THE RISKS AND RELEASE:</strong> Participants recognize
                        that there are certain inherent risks associated with the above-described
                        activity and assume full responsibility for personal injury to oneself and
                        family members and further release and discharge Funlandia Kid Café for
                        injury, loss, or damage arising out of Participants' use of or presence upon
                        the facilities of Funlandia Kid Café whether caused by the fault of
                        Participants, Funlandia Kid Café, or other third parties. Participants
                        expressly agree and promise to accept and assume all risks existing in this
                        activity. Participation in this activity is purely voluntary, and
                        Participants elect to participate despite the risks.
                      </li>
                      <br />
                      <li>
                        <strong>INDEMNIFICATION:</strong> Participants agree to indemnify, defend,
                        and hold harmless Funlandia Kid Café against all claims, causes of action,
                        damages, judgments, costs, or expenses, including attorney fees and other
                        litigation costs, which may in any way arise from Participants' use of, or
                        presence upon, the facilities of Funlandia Kid Café.
                      </li>
                      <br />
                      <li>
                        <strong>FEES:</strong> Participants agree to pay for all damages to the
                        facilities of Funlandia Kid Café caused by any neglectful, reckless, or
                        willful actions by Participants.
                      </li>
                      <br />
                      <li>
                        <strong>PHOTO RELEASE:</strong> Participants hereby grant Funlandia Kid Café
                        permission to use images or likeness in a photograph, video, or other
                        digital media in all of its publications, including web-based publications,
                        without payment, consent, or other consideration.
                      </li>
                      <br />
                    </ol>

                    <p>
                      By signing this document, Participants acknowledge that if Participants are
                      injured or property is damaged during participation in Activities,
                      Participants are expressing to a court of law that they have waived any right
                      to maintain or bring upon any lawsuit against Funlandia Kid Café based on any
                      claim from which Participants have released them herein. Participant agrees
                      that this document is valid for all subsequent visits and participation at
                      Funlandia Kid Café. Participants have had sufficient opportunity to read this
                      entire document, have read and understood this document, and agree to be bound
                      by its terms.
                    </p>
                    <br />
                    <hr />
                    <br />

                    {/* Parent/Legal Guardian Information */}
                    <h3>Parent/Legal Guardian Information</h3>
                    <div className="form-row">
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="firstName">
                          First Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                          value={formData.firstName}
                          onChange={handleChange}
                          required
                        />
                        {errors.firstName && (
                          <div className="invalid-feedback">{errors.firstName}</div>
                        )}
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="lastName">
                          Last Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                          value={formData.lastName}
                          onChange={handleChange}
                          required
                        />
                        {errors.lastName && (
                          <div className="invalid-feedback">{errors.lastName}</div>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="email">
                          Email Address <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <label htmlFor="phone">
                          Phone <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          name="phone"
                          className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                        {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                      </div>
                    </div>

                    {/* Participants' Information */}
                    <h4>Participants' Information</h4>
                    {formData.children.map((child, index) => (
                      <div key={index} className="form-row mb-3">
                        <div className="form-group col-md-6 mb-3">
                          <label htmlFor={`childName_${index}`}>Child's Name {index + 1}</label>
                          <input
                            type="text"
                            id={`childName_${index}`}
                            name={`children_name_${index}`}
                            className={`form-control `}
                            value={child.name}
                            onChange={(e) => handleChange(e, index)}
                            required={index === 0} // Make first child required
                          />
                          {errors.children[index]?.name && (
                            <div className="invalid-feedback">{errors.children[index].name}</div>
                          )}
                        </div>
                        <div className="form-group col-md-6 mb-3">
                          <label htmlFor={`childDOB_${index}`}>Child's DOB {index + 1}</label>
                          <input
                            type="date"
                            id={`childDOB_${index}`}
                            name={`children_dob_${index}`}
                            className={`form-control `}
                            value={child.dob}
                            onChange={(e) => handleChange(e, index)}
                            required={index === 0} // Make first child DOB required
                          />
                          {errors.children[index]?.dob && (
                            <div className="invalid-feedback">{errors.children[index].dob}</div>
                          )}
                        </div>
                        {/* Remove child button */}
                        {formData.children.length > 1 && (
                          <div className="form-group col-md-12 text-right">
                            <button
                              type="button"
                              onClick={() => removeChild(index)}
                              className="btn btn-remove"
                            >
                              Remove Child
                            </button>
                          </div>
                        )}
                      </div>
                    ))}

                    {/* Add Child Button */}
                    <button
                      type="button"
                      onClick={addChild}
                      className="btn btn-custom btn-secondary mb-3"
                    >
                      Add Another Child
                    </button>

                    {/* Signature Capture */}
                    <h4>Signature</h4>
                    <div className="signature-container mb-3 ">
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          width: canvasDimensions.width,
                          height: canvasDimensions.height,
                          className: 'sigCanvas border rounded ',
                        }}
                        ref={sigCanvas}
                        onEnd={() => {
                          if (!sigCanvas.current.isEmpty()) {
                            setSignature(sigCanvas.current.toDataURL('image/png'));
                          }
                        }}
                        backgroundColor="rgba(255,255,255,1)"
                      />
                    </div>
                    {errors.signature && (
                      <div className="invalid-feedback d-block">{errors.signature}</div>
                    )}
                    <div style={{ marginBottom: '20px' }}>
                      <button
                        type="button"
                        onClick={clearSignature}
                        className="btn btn-custom btn-secondary"
                        style={{ marginRight: '10px' }}
                      >
                        Clear Signature
                      </button>
                    </div>

                    {/* Consent Checkbox */}
                    {/* <div className="form-group form-check" style={{ marginTop: '20px' }}>
                      <input
                        type="checkbox"
                        id="emailConfirmation"
                        name="emailConfirmation"
                        className="form-check-input custom-checkbox"
                        checked={formData.emailConfirmation}
                        onChange={handleChange}
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="emailConfirmation"
                        style={{ fontWeight: 'bold', cursor: 'pointer', fontSize: 18 }}
                      >
                        I confirm my email address is spelled correctly.
                      </label>
                    </div> */}

                    <div
                      className="form-group form-check custom-checkbox-container-w"
                      style={{ marginTop: '20px' }}
                    >
                      <input
                        type="checkbox"
                        id="emailConfirmation"
                        name="emailConfirmation"
                        className="form-check-input custom-checkbox-w"
                        checked={formData.emailConfirmation}
                        onChange={handleChange}
                        required
                      />
                      <label
                        className="form-check-label-w"
                        htmlFor="emailConfirmation"
                        style={{ fontWeight: 'bold', cursor: 'pointer', fontSize: 18 }}
                      >
                        I confirm my email address is spelled correctly.
                      </label>
                      {errors.emailConfirmation && (
                        <div className="invalid-feedback d-block">{errors.emailConfirmation}</div>
                      )}
                    </div>

                    {/* Agreement Checkbox */}
                    {/* <div className="form-group form-check">
                      <input
                        type="checkbox"
                        id="agreement"
                        name="agreement"
                        className="form-check-input custom-checkbox"
                        checked={formData.agreement}
                        onChange={handleChange}
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="agreement"
                        style={{ fontWeight: 'bold', cursor: 'pointer', fontSize: 18 }}
                      >
                        By signing this document, Participants acknowledge that if Participants are
                        injured or property is damaged during participation in Activities,
                        Participants are expressing to a court of law to have waived any right to
                        maintain or bring upon any lawsuit against Funlandia Kid Café based on any
                        claim from which Participants have released them herein. Participant agrees
                        that this document is valid for all subsequent visits and participation at
                        Funlandia Kid Café. Participants have had sufficient opportunity to read
                        this entire document, have read and understood this document, and agree to
                        be bound by its terms.
                      </label>
                      {errors.agreement && (
                        <div className="invalid-feedback d-block">{errors.agreement}</div>
                      )}
                    </div> */}

                    <div
                      className="form-group form-check custom-checkbox-container-w"
                      style={{ marginTop: '20px' }}
                    >
                      <input
                        type="checkbox"
                        id="agreement"
                        name="agreement"
                        className="form-check-input custom-checkbox-w"
                        checked={formData.agreement}
                        onChange={handleChange}
                        required
                      />
                      <label
                        className="form-check-label-w"
                        htmlFor="agreement"
                        style={{ fontWeight: 'bold', cursor: 'pointer', fontSize: 18 }}
                      >
                        By signing this document, Participants acknowledge that if Participants are
                        injured or property is damaged during participation in Activities,
                        Participants are expressing to a court of law to have waived any right to
                        maintain or bring upon any lawsuit against Funlandia Kid Café based on any
                        claim from which Participants have released them herein. Participant agrees
                        that this document is valid for all subsequent visits and participation at
                        Funlandia Kid Café. Participants have had sufficient opportunity to read
                        this entire document, have read and understood this document, and agree to
                        be bound by its terms.
                      </label>
                      {errors.agreement && (
                        <div className="invalid-feedback d-block">{errors.agreement}</div>
                      )}
                    </div>

                    {/* Submit Button */}
                    <div className="text-center">
                      <br />
                      <button
                        type="submit"
                        className={`btn btn-custom btn-primary ${!isFormValid() ? 'disabled' : ''}`}
                        disabled={!isFormValid()}
                      >
                        Submit Waiver
                      </button>
                    </div>
                  </form>
                ) : (
                  <PdfGenerator data={pdfData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
